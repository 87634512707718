@import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import 'app/shared/styles/mixins';
@import 'app/shared/styles/form';
@import 'app/shared/styles/button';
@import 'app/shared/styles/sticky-header';
@import 'app/shared/styles/sidenav';

$primary-color: #461e7d;
$secondary-color: #5738ff;
$gray: #888888;

ngx-global-contact-footer {
  display: block;
  padding: 40px 25px 25px;
  a {
    margin: inherit;
    color: var(--link-color);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
}

body {
  margin: 0;
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

.alert {
  position: relative;
  padding: 0;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-padding {
  padding: 0.75rem 1.25rem;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

lib-ngx-deeplinker {
  button {
    background-color: #fff;
    color: #461e7d;
    border: 1px solid #461e7d;
    padding: 6px 10px !important;
  }
}

.mobile-only {
  display: none;
}
.desktop-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

.sdps-list::-webkit-scrollbar {
  width: 8px;
}

.sdps-list::-webkit-scrollbar-track {
  border-radius: 30px;
}

.sdps-list::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: var(--primary-color);
}
